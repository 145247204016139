import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import AllArticlesSection from "../components/AllArticlesSection";

const IndexPage = ({ data: { allMarkdownRemark } }) => (
	<Layout>
		<SEO title="Home" />
		{/* <AllArticlesSection
			allMarkdownRemark={allMarkdownRemark}
		></AllArticlesSection>
    */}
		<section>
			<h2>About Me</h2>
			<p>
				Founded a startup to help people{" "}
				<a
					href="https://techcrunch.com/2018/05/10/necto-looks-to-help-individuals-get-their-own-local-isp-businesses-off-the-ground"
					target="_blank"
					rel="noopener noreferrer"
				>
					start their own internet service providers
				</a>
				, ran an{" "}
				<a
					href="https://www.contractormag.com/plumbing/article/20881083/ars-acquires-unique-air-services-in-the-florida-gulf-coast"
					target="_blank"
					rel="noopener noreferrer"
				>
					air conditioning company
				</a>
				, and built software to make those industries work better. Originally
				from Vancouver, recently left San Francisco, and currently mildly
				nomadic.
			</p>
			<p>
				<a
					href="https://twitter.com/montasaurus_rex"
					target="_blank"
					rel="noopener noreferrer"
				>
					my twitter
				</a>
			</p>
		</section>
	</Layout>
);

export default IndexPage;

export const pageQuery = graphql`
	{
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			limit: 1000
		) {
			edges {
				node {
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						path
						title
						teaser
						noindex
					}
				}
			}
		}
	}
`;
